import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Link } from "react-feather";

const Projects = () => {
  return (
    <Container id="projects" style={{ minHeight: "100vh", marginTop: "10%" }}>
      <p style={{ color: "#8d8d8d", fontFamily: "Shadows Into Light" }}>
        {"<projects>"}
      </p>
      <Row className="mx-3 my-5">
        <Col md={12} lg={4} className="my-2">
          <div className="project-item" style={{ position: "relative" }}>
            <Image className="project-image" src="jive.png" fluid />
            <div className="project-overlay">
              <div className="project-overlay-text">
                Jive{" "}
                <Link
                  color="white"
                  size={16}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
            </div>
          </div>
        </Col>

        <Col md={12} lg={4} className="my-2">
          <div className="project-item" style={{ position: "relative" }}>
            <Image className="project-image" src="covidreport.png" fluid />
            <a
              href="https://github.com/tranngo/covid-report"
              target="_blank"
              className="project-overlay"
              rel="noreferrer"
            >
              <div className="project-overlay-text">
                COVID-19 Report{" "}
                <Link
                  color="white"
                  size={16}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
            </a>
          </div>
        </Col>

        <Col md={12} lg={4} className="my-2">
          <div className="project-item" style={{ position: "relative" }}>
            <Image className="project-image" src="stockportfolio.png" fluid />
            <a
              href="https://github.com/tranngo/stock-portfolio-management"
              target="_blank"
              className="project-overlay"
              rel="noreferrer"
            >
              <div className="project-overlay-text">
                Stock Portfolio Management{" "}
                <Link
                  color="white"
                  size={16}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
            </a>
          </div>
        </Col>
      </Row>
      <p style={{ color: "#8d8d8d", fontFamily: "Shadows Into Light" }}>
        {"</projects>"}
      </p>
    </Container>
  );
};

export default Projects;
