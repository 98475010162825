import React from "react";
import { Menu } from "react-feather";

const Navbar = ({ setIsSidebarOpen }) => {
  return (
    <nav>
      <div id="nav-container">
        <img id="nav-logo" src="signature.svg" alt="Navigation bar logo" />
        <div id="nav-mobile-icon" onClick={() => setIsSidebarOpen(true)}>
          <Menu style={{ backgroundColor: "transparent", color: "#1f1a17" }} />
        </div>
        <div id="nav-right-side">
          <ul id="nav-menu">
            <li className="nav-item">
              <a href="#about">{"< About />"}</a>
            </li>
            <li className="nav-item">
              <a href="#experience">{"< Experience />"}</a>
            </li>
            <li className="nav-item">
              <a href="#projects">{"< Projects />"}</a>
            </li>
            {/* <li className="nav-item">
              <a href="#">{"< Photography />"}</a>
            </li> */}
          </ul>
          <a href="#contact">
            <button id="nav-contact-btn">Contact</button>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
