import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactTooltip from "react-tooltip";
import {
  Python,
  Cplusplus,
  Java,
  Html5,
  CssThree,
  Javascript,
  Typescript,
  Php,
  Sass,
  ReactJs,
  Django,
  Git,
  Jquery,
  Mysql,
  Bootstrap,
  Postman,
  Figma,
} from "@icons-pack/react-simple-icons";
import Timeline from "../components/timeline/Timeline";

const SKILL_HOVER_COLOR = "red";
const SKILL_UNHOVER_COLOR = "#1f1a17";

const Experience = () => {
  const [skillHover, setSkillHover] = useState("");

  return (
    <Container id="experience" style={{ minHeight: "100vh" }}>
      <p style={{ color: "#8d8d8d", fontFamily: "Shadows Into Light" }}>
        {"<experience>"}
      </p>
      <Row>
        <Col md={12} lg={6}>
          <div className="mx-3">
            <h2 style={{ fontWeight: 700, fontSize: "30px" }}>Experience</h2>

            {/* <h4 style={{ fontWeight: 700, fontSize: "16px" }}>
              Sidearm Sports
            </h4>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h5
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#0088a9",
                  textTransform: "uppercase",
                }}
              >
                Full-Stack Developer
              </h5>
              <h5
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#0088a9",
                  textTransform: "uppercase",
                }}
              >
                August 2021 – Present
              </h5>
            </div> */}

            <h4
              style={{
                fontWeight: 700,
                fontSize: "16px",
                marginTop: ".5rem",
              }}
            >
              Better Inc.
            </h4>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h5
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#0088a9",
                  textTransform: "uppercase",
                }}
              >
                Software Engineer
              </h5>
              <h5
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#0088a9",
                  textTransform: "uppercase",
                }}
              >
                April 2020 – May 2021
              </h5>
            </div>
            <ul>
              <li>
                Built productivity and social media mobile application using{" "}
                <strong>React Native</strong>, <strong>Django</strong>, and{" "}
                <strong>AWS</strong>
              </li>
              <li>
                Created <strong>REST endpoints</strong> for client-server
                communication; tested endpoints using <strong>Postman</strong>
              </li>
              <li>
                Developed front-end for 9 core screens adhering to sets of
                stakeholders and mockups
              </li>
              <li>
                Accelerated development time of 18 new screens by designing a
                reusable library of <strong>React Native components</strong>{" "}
                used by other software engineers
              </li>
            </ul>
          </div>

          <div className="mx-3">
            <h2 style={{ fontWeight: 700, fontSize: "30px" }}>Skills</h2>
            <h4 style={{ fontWeight: 700, fontSize: "16px" }}>Languages</h4>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "1rem",
              }}
            >
              <ReactTooltip />
              <Python
                color={
                  skillHover === "Python"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="Python"
                onMouseEnter={() => {
                  setSkillHover("Python");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <Cplusplus
                color={
                  skillHover === "Cplusplus"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="C++"
                onMouseEnter={() => {
                  setSkillHover("Cplusplus");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <Java
                color={
                  skillHover === "Java"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="Java"
                onMouseEnter={() => {
                  setSkillHover("Java");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <Html5
                color={
                  skillHover === "Html5"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="HTML"
                onMouseEnter={() => {
                  setSkillHover("Html5");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <CssThree
                color={
                  skillHover === "CssThree"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="CSS"
                onMouseEnter={() => {
                  setSkillHover("CssThree");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <Javascript
                color={
                  skillHover === "Javascript"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="JavaScript"
                onMouseEnter={() => {
                  setSkillHover("Javascript");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <Typescript
                color={
                  skillHover === "Typescript"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="TypeScript"
                onMouseEnter={() => {
                  setSkillHover("Typescript");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <Php
                color={
                  skillHover === "Php" ? SKILL_HOVER_COLOR : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="PHP"
                onMouseEnter={() => {
                  setSkillHover("Php");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <Sass
                color={
                  skillHover === "Sass"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                data-tip="Sass"
                onMouseEnter={() => {
                  setSkillHover("Sass");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
            </div>
            <h4
              style={{ fontWeight: 700, fontSize: "16px", marginTop: ".5rem" }}
            >
              Libraries/Tools
            </h4>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "1rem",
              }}
            >
              <ReactTooltip />
              <ReactJs
                color={
                  skillHover === "ReactJs"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="React/React Native"
                onMouseEnter={() => {
                  setSkillHover("ReactJs");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <Django
                color={
                  skillHover === "Django"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="Django"
                onMouseEnter={() => {
                  setSkillHover("Django");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <Git
                color={
                  skillHover === "Git" ? SKILL_HOVER_COLOR : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="Git"
                onMouseEnter={() => {
                  setSkillHover("Git");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <Jquery
                color={
                  skillHover === "Jquery"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="jQuery"
                onMouseEnter={() => {
                  setSkillHover("Jquery");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <Mysql
                color={
                  skillHover === "Mysql"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="MySQL"
                onMouseEnter={() => {
                  setSkillHover("Mysql");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <Bootstrap
                color={
                  skillHover === "Bootstrap"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="Bootstrap"
                onMouseEnter={() => {
                  setSkillHover("Bootstrap");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <Postman
                color={
                  skillHover === "Postman"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="Postman"
                onMouseEnter={() => {
                  setSkillHover("Postman");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
              <ReactTooltip />
              <Figma
                color={
                  skillHover === "Figma"
                    ? SKILL_HOVER_COLOR
                    : SKILL_UNHOVER_COLOR
                }
                size={35}
                style={{ marginRight: "5px" }}
                data-tip="Figma"
                onMouseEnter={() => {
                  setSkillHover("Figma");
                }}
                onMouseLeave={() => {
                  setSkillHover("");
                }}
              />
            </div>
            <h4
              style={{ fontWeight: 700, fontSize: "16px", marginTop: ".5rem" }}
            >
              Other
            </h4>
            <p>Agile Methodologies, REST APIs, Jira, Azure DevOps</p>
          </div>
        </Col>
        <Col md={12} lg={6}>
          <div className="mx-3">
            <h2 style={{ fontWeight: 700, fontSize: "30px" }}>Timeline</h2>
            <Timeline />
          </div>
        </Col>
      </Row>
      <p style={{ color: "#8d8d8d", fontFamily: "Shadows Into Light" }}>
        {"</experience>"}
      </p>
    </Container>
  );
};

export default Experience;
