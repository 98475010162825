import React, { useEffect } from "react";
import { X } from "react-feather";
import "./styles.css";

const Sidebar = ({ setIsSidebarOpen }) => {
  useEffect(() => {
    const sidebarContainer = document.querySelector("#sidebar-container");
    sidebarContainer.style.opacity = "100%";
    sidebarContainer.style.top = 0;
  }, []);

  return (
    <aside id="sidebar-container">
      <div id="sidebar-close-icon-container">
        <X
          style={{ backgroundColor: "transparent", color: "#1f1a17" }}
          onClick={() => {
            const sidebarContainer =
              document.querySelector("#sidebar-container");
            sidebarContainer.style.top = "-100%";
            sidebarContainer.style.opacity = "0";
            setIsSidebarOpen(false);
          }}
        />
      </div>
      <ul id="sidebar-menu">
        <li className="sidebar-item">
          <a
            href="#about"
            onClick={() => {
              const sidebarContainer =
                document.querySelector("#sidebar-container");
              sidebarContainer.style.top = "-100%";
              sidebarContainer.style.opacity = "0";
              setIsSidebarOpen(false);
            }}
          >
            {"< About />"}
          </a>
        </li>
        <li className="sidebar-item">
          <a
            href="#experience"
            onClick={() => {
              const sidebarContainer =
                document.querySelector("#sidebar-container");
              sidebarContainer.style.top = "-100%";
              sidebarContainer.style.opacity = "0";
              setIsSidebarOpen(false);
            }}
          >
            {"< Experience />"}
          </a>
        </li>
        <li className="sidebar-item">
          <a
            href="#projects"
            onClick={() => {
              const sidebarContainer =
                document.querySelector("#sidebar-container");
              sidebarContainer.style.top = "-100%";
              sidebarContainer.style.opacity = "0";
              setIsSidebarOpen(false);
            }}
          >
            {"< Projects />"}
          </a>
        </li>
        {/* <li className="sidebar-item">
          <a href="#">{"< Photography />"}</a>
        </li> */}
      </ul>
      <div id="sidebar-btn-container">
        <a href="#contact">
          <button
            id="sidebar-contact-btn"
            onClick={() => {
              const sidebarContainer =
                document.querySelector("#sidebar-container");
              sidebarContainer.style.top = "-100%";
              sidebarContainer.style.opacity = "0";
              setIsSidebarOpen(false);
            }}
          >
            Contact
          </button>
        </a>
      </div>
    </aside>
  );
};

export default Sidebar;
