import React from "react";
import { SocialIcon } from "react-social-icons";

const Footer = () => {
  return (
    <footer
      className="py-3"
      style={{
        backgroundColor: "#1f1a17",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
      }}
    >
      <SocialIcon
        url="https://www.linkedin.com/in/tranbngo"
        bgColor="transparent"
        fgColor="white"
        target="_blank"
        rel="noreferrer"
      />
      <SocialIcon
        url="https://github.com/tranngo"
        bgColor="transparent"
        fgColor="white"
        target="_blank"
        rel="noreferrer"
      />
    </footer>
  );
};

export default Footer;
