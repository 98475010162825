import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

const About = () => {
  return (
    <Container id="about" style={{ minHeight: "100vh" }}>
      <Row>
        <Col
          className="d-md-block"
          s={12}
          md={6}
          style={{
            display: "none",
            alignSelf: "center",
            paddingBottom: "15%",
          }}
        >
          <Content />
        </Col>
        <Col
          className="d-md-none"
          s={12}
          md={6}
          style={{
            alignSelf: "center",
          }}
        >
          <Content />
        </Col>
        <Col className="d-flex flex-column align-items-center" s={0} md={6}>
          <Image src="me.png" style={{ width: "90%" }} fluid />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-end",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <p
              style={{
                color: "#1f1a17",
                fontFamily: "Shadows Into Light",
                transform: "rotate(-20deg)",
              }}
            >
              that's me!
            </p>
            <Image
              src="arrow.svg"
              style={{
                transform: "rotate(35deg) rotateY(180deg)",
                marginLeft: "20px",
              }}
              fluid
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default About;

const Content = () => {
  return (
    <>
      <p style={{ color: "#8d8d8d", fontFamily: "Shadows Into Light" }}>
        {"<about>"}
      </p>
      <div className="mx-3">
        <h1 style={{ fontWeight: 700, fontSize: "48px" }}>
          Hello, my name is Tran.
        </h1>
        <p>
          I'm a software engineer, photographer, and tech enthusiast currently
          based in Syracuse, NY.
        </p>
        {/* <p>
          I'm a software engineer, photographer, and tech enthusiast. I'm
          currently building interactive, human-centered products for collegiate
          sports fans at Sidearm Sports.
        </p> */}
      </div>
      <p style={{ color: "#8d8d8d", fontFamily: "Shadows Into Light" }}>
        {"</about>"}
      </p>
    </>
  );
};
