import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isMessageEmpty, setIsMessageEmpty] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setIsEmailInvalid(true);
    }

    if (!message || message.length === 0) {
      setIsMessageEmpty(true);
    }

    if (!isEmailInvalid && !isMessageEmpty) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: name,
          email: email,
          subject: subject,
          message: message,
        }),
      };
      fetch(
        "https://361080a4347f38f7196481d2f93d1fee.m.pipedream.net",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.success === true) {
            console.log("true");
          }
        });
    }
  };

  const validateEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  };

  return (
    <Container id="contact">
      <p style={{ color: "#8d8d8d", fontFamily: "Shadows Into Light" }}>
        {"<contact>"}
      </p>
      <Row className="my-5">
        <Col>
          <div className="mx-3">
            <h2 style={{ fontWeight: 700, fontSize: "30px" }}>
              Let's Get in Touch
            </h2>
            <h4 style={{ fontWeight: 400, fontSize: "16px" }} className="mb-4">
              Whether you just want to say hello or have a question, my inbox is
              always open!
            </h4>
            <Form>
              <Row>
                <Col md>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Email *"
                      value={email}
                      onChange={(e) => {
                        if (isEmailInvalid) {
                          setIsEmailInvalid(false);
                        }
                        setEmail(e.target.value);
                      }}
                      isInvalid={isEmailInvalid ? true : false}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicSubject">
                    <Form.Control
                      type="text"
                      placeholder="Subject"
                      value={subject}
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicMessage">
                    <Form.Control
                      as="textarea"
                      placeholder="Message *"
                      style={{ height: "150px" }}
                      value={message}
                      onChange={(e) => {
                        if (isMessageEmpty) {
                          setIsMessageEmpty(false);
                        }
                        setMessage(e.target.value);
                      }}
                      isInvalid={isMessageEmpty ? true : false}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      color: "black",
                    }}
                  >
                    trxnngo@gmail.com • (315) 420-3679
                  </p>
                  <Form.Group>
                    <button
                      id="submit-contact-btn"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      Submit
                    </button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        <Col className="d-none d-lg-block"></Col>
      </Row>
      <p style={{ color: "#8d8d8d", fontFamily: "Shadows Into Light" }}>
        {"</contact>"}
      </p>
      <Col
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <div
          className="mb-3"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          }}
        >
          <Image
            src="arrow2.svg"
            style={{
              transform: "rotate(10deg) rotateX(180deg) rotateY(180deg)",
              marginLeft: "20px",
            }}
            fluid
          />
          <p
            className="mx-3"
            style={{
              color: "black",
              fontFamily: "Shadows Into Light",
            }}
          >
            find me on <br />
            these platforms
          </p>
        </div>
      </Col>
    </Container>
  );
};

export default Contact;
