import React, { useState } from "react";
import Badge from "react-bootstrap/Badge";
import { ChevronDown } from "react-feather";
import "./styles.css";

const Timeline = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="main-card my-3 card">
      <div className="card-body">
        <h5 className="card-title">2021</h5>
        <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
          <div className="vertical-timeline-item vertical-timeline-element">
            <div>
              <span className="vertical-timeline-element-icon">
                <Badge
                  className="badge badge-dot badge-dot-xl badge-success"
                  bg="warning"
                >
                  {" "}
                </Badge>
              </span>
              <div className="vertical-timeline-element-content">
                <h4 className="timeline-title">Moved back to Syracuse, NY</h4>
                <p>It's great to be back in my hometown!</p>
                <span className="vertical-timeline-element-date">June</span>
              </div>
            </div>
          </div>
          {/* <div className="vertical-timeline-item vertical-timeline-element">
            <div>
              <span className="vertical-timeline-element-icon">
                <Badge
                  className="badge badge-dot badge-dot-xl badge-success"
                  bg="warning"
                >
                  {" "}
                </Badge>
              </span>
              <div className="vertical-timeline-element-content">
                <h4 className="timeline-title">
                  Joined Sidearm Sports as a full-stack developer
                </h4>
                <p>I'm excited about this new position!</p>
                <span className="vertical-timeline-element-date">August</span>
              </div>
            </div>
          </div> */}
          <div className="vertical-timeline-item vertical-timeline-element">
            <div>
              <span className="vertical-timeline-element-icon">
                <Badge
                  className="badge badge-dot badge-dot-xl badge-success"
                  bg="warning"
                >
                  {" "}
                </Badge>
              </span>
              <div className="vertical-timeline-element-content">
                <h4 className="timeline-title">
                  Graduated from the University of Southern California{" "}
                  <p
                    style={{
                      display: "inline",
                      color: "#9d2235",
                      fontFamily: "Shadows Into Light",
                      paddingLeft: "2px",
                      textTransform: "lowercase",
                    }}
                  >
                    (fight on!)
                  </p>
                </h4>
                <p>
                  This is one of my proudest achievements because I was one of
                  the first in my family to attend college and, through
                  resilience and perseverance, graduated with a B.S. in Computer
                  Science from a top university.
                </p>
                <span className="vertical-timeline-element-date">May</span>
              </div>
            </div>
          </div>
        </div>

        <h5 className="card-title">2020</h5>
        <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
          <div className="vertical-timeline-item vertical-timeline-element">
            <div>
              <span className="vertical-timeline-element-icon">
                <Badge
                  className="badge badge-dot badge-dot-xl badge-success"
                  bg="warning"
                >
                  {" "}
                </Badge>
              </span>
              <div className="vertical-timeline-element-content">
                <h4 className="timeline-title">
                  Joined Better Inc. as a software engineer
                </h4>
                <p>
                  I met the founders at a{" "}
                  <a
                    href="https://devpost.com/software/better-tpgi61"
                    target="_blank"
                    rel="noreferrer"
                  >
                    hackathon
                  </a>
                  , efficiently learned React Native and Django over the course
                  of three days to build a proof of concept for their
                  early-stage startup, and was immediately offered a part-time
                  position.
                </p>
                <span className="vertical-timeline-element-date">April</span>
              </div>
            </div>
          </div>
        </div>

        <p
          style={{
            margin: 0,
            padding: 0,
            textAlign: "center",
            cursor: "pointer",
            fontWeight: "500",
            display: showMore ? "none" : "block",
          }}
          onClick={() => setShowMore(true)}
        >
          See More <ChevronDown color="#1f1a17" size={20} />
        </p>
        <div style={{ display: showMore ? "block" : "none" }}>
          <h5 className="card-title">2017</h5>
          <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
            <div className="vertical-timeline-item vertical-timeline-element">
              <div>
                <span className="vertical-timeline-element-icon">
                  <Badge
                    className="badge badge-dot badge-dot-xl badge-success"
                    bg="warning"
                  >
                    {" "}
                  </Badge>
                </span>
                <div className="vertical-timeline-element-content">
                  <h4 className="timeline-title">Moved to Los Angeles, CA</h4>
                  <p>
                    After a six hour flight to the opposite side of the country,
                    I landed in LA for the first time.
                  </p>
                  <span className="vertical-timeline-element-date">August</span>
                </div>
              </div>
            </div>
            <div className="vertical-timeline-item vertical-timeline-element">
              <div>
                <span className="vertical-timeline-element-icon">
                  <Badge
                    className="badge badge-dot badge-dot-xl badge-success"
                    bg="warning"
                  >
                    {" "}
                  </Badge>
                </span>
                <div className="vertical-timeline-element-content">
                  <h4 className="timeline-title">Graduated from high school</h4>
                  <p>
                    I graduated from the Institute of Technology @ Syracuse
                    Central with a Regents Diploma with Advanced Designation
                    with Honors.
                  </p>
                  <span className="vertical-timeline-element-date">June</span>
                </div>
              </div>
            </div>
            <div className="vertical-timeline-item vertical-timeline-element">
              <div>
                <span className="vertical-timeline-element-icon">
                  <Badge
                    className="badge badge-dot badge-dot-xl badge-success"
                    bg="warning"
                  >
                    {" "}
                  </Badge>
                </span>
                <div className="vertical-timeline-element-content">
                  <h4 className="timeline-title">
                    Accepted into the University of Southern California
                  </h4>
                  <p>It felt like a dream come true!</p>
                  <span className="vertical-timeline-element-date">March</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
